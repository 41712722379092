@import '../../sass/colors.scss';

// TODO P3 animate new comments with fading background color
.Comment {
  padding-left: 10px;
  margin: 0 0 15px 3vw;

  border-left: 1px solid lighten($lightGrey, 15%);

  &--top-level {
    margin-left: 0;
    border-left: 0;
  }

  &__avatar-content-wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__avatar {
    padding-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__author-date {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3px;

    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }

  &__author {
    word-break: break-word;
    overflow-wrap: break-word;
  }

  &__content {
    margin: 0;

    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;

    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  &__actions {
    text-align: left;
    margin: 0 0 5px 42px;
  }
}