@import '../../sass/colors.scss';

.StandingsTableRow {
  &--self {
    background-color: lighten($purple, 50%);
    font-weight: bold;
  }

  .TableRowColumn {
    &__user-wrapper:hover {
      cursor: pointer;
    }

    &__username {
      overflow: hidden;
      white-space: normal;
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }
}