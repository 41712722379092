@import '../sass/colors.scss';

.RtgSeparator {
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;
  max-width: 850px;

  &__text {
    color: $grey;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 15px;
    margin: 15px 0;
    background-color: white;
    z-index: 1;
  }

  &:after {
    content: "";
    left: 0;
    top: 50%;
    width: 100%;
    border-top: 1px dotted $lightGold;
    position: absolute;
  }
}