@import '../sass/colors';

.ExtraBetCard {
  margin: 25px 0;

  @media only screen and (min-width: 640px) {
    margin: 25px auto;
    max-width: 620px;
  }

  &__subtitle-points,
  &__subtitle-deadline {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__subtitle-deadline {
    color: $error;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width : 480px) {
      flex-direction: row;
    }
  }

  &__result-info {
    box-sizing: border-box;
    display: flex;
    align-items: center;

    width: 100%;
    padding: 15px;

    background-color: lighten($ribbonNeutral, 30%);
    border-top: 1px solid $grey;
  }

  &__result-info--finished {
    color: white;
    background-color: $betResultNiete;

    &.volltreffer {
      background-color: $betResultVolltreffer;
    }
  }

  &__bet-info {
    padding: 0 15px 15px;
  }

  &__bet-info-bet {
    font-weight: bold;
    text-transform: uppercase;
  }
}