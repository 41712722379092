.BigEditableAvatar {
  &__avatar-elem:hover {
    cursor: pointer;
  }

  &__feedback {
    margin: 15px auto;
    max-width: 500px;
  }

  &__rotation-wrapper {
    display: flex;
    justify-content: space-between;
    width: 110px;
    margin: 10px auto 0;
  }

  &__slider-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__slider {
    flex-grow: 1;
  }
}