@import '../sass/mixins/skeleton';

.GameCard {
  margin: 0 auto;
  padding: 16px 0 40px;

  &.auto-hide {
    .hometeam, .awayteam {
      display: none;
      @media only screen and (min-width: 680px) {
        display: flex;
      }
    }
  }

  &__inner {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    box-sizing: border-box;

    border-left: 20px solid white;
    border-right: 20px solid white;

    font-family: 'Roboto', sans-serif;
    background-color: #e3e3e3;

    &--skeleton {
      @include skeleton('game-skeleton');
    }
  }

  .hometeam, .awayteam {
    display: flex;
    text-transform: uppercase;
    font-size: 20px;

    @media only screen and (min-width : 680px) {
      align-items: center;
      flex-grow: 1
    }
  }

  .hometeam {
    padding: 0 5px 0 35px;
    justify-content: flex-start;
  }

  .awayteam {
    padding: 0 35px 0 5px;
    justify-content: flex-end;
  }

  @media only screen and (min-width : 680px) {
    max-width: 850px;
  }
}