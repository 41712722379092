.LoginForm {
  margin: 20px;
  padding: 20px;
  text-align: left;
  max-width: 400px;
  box-sizing: border-box;

  &__heading {
    margin: 0;
    text-align: center;
  }

  &__form {
    width: 100%;
  }

  &__formError {
    margin-top: 20px;
    text-align: center;
  }

  &__second-button-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  @media only screen and (min-width : 480px) {
    margin: 20px auto;
  }
}