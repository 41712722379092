@import '../sass/mixins/skeleton';
@import '../sass/z-indexes';

$size: 90px;

.CountryFlag {
  position: relative;
  margin: 0 -25px;
  z-index: $zGameCardFlags;
  user-select: none;

  &__flag,
  &--skeleton {
    width: $size;
    height: $size;
  }

  &__name {
    white-space: nowrap;
    position: absolute;
    width: 100%;
    margin-top: 3px;
    text-align: center;
    text-transform: uppercase;

    font-size: 14px;
  }
  &__name.auto-hide {
    @media only screen and (min-width : 680px) {
      display: none;
    }
  }

  &--skeleton {
    @include skeleton('flag-skeleton', $shine-width: 70%, $shine-overflow: 200%);
    border-radius: 50%;
    background-color: #e3e3e3;
  }
}