@import '../../sass/utils';
@import '../../sass/z-indexes';

.CurrentGames {
  position: relative;

  width: 100%;
  // TODO P3 magic number - content is dynamic. How can this be solved better?
  height: 330px;
  min-width: 320px;
  margin: 0 auto;

  overflow: hidden;
  box-sizing: border-box;

  transition: height 300ms $mdEasingStandard;

  &.bet-stats-open {
    height: 1015px;
  }

  &__game-card-container {
    position: absolute;

    display: flex;
    align-content: center;
    justify-content: space-between;

    transition: transform 200ms $mdEasingDecelerate;

    @media only screen and (min-width : 768px) {
      transition: transform 375ms $mdEasingDecelerate;
    }

    &.no-animate {
      transition: none;
    }
  }

  &__game-card-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__scroll-button {
    // magic spacing - height of the container is dynamic
    top: 255px;
    z-index: $zCurrentGamesScrollButtons;

    @media only screen and (min-width : 375px) {
      top: 156px;
      margin: 0 20px;
    }
  }
}