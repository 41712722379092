@import '../../sass/globals';
@import '../../sass/utils';
@import '../../sass/z-indexes';

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zHeader;
  transition: top 200ms $mdEasingDecelerate;

  &.nav-up {
    top: -$headerHeight - 10px;
  }

  &__title:hover {
    cursor: pointer;
  }

  &__title--desktop {
    display: none !important;
    @media only screen and (min-width : 768px) {
      display: flex !important;
    }
  }

  &__title--mobile {
    @media only screen and (min-width : 768px) {
      display: none !important;
    }
  }
}