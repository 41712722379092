@import '../../sass/_z-indexes.scss';

.ProfileCard {
  position: relative;
  height: 150px;
  padding: 20px 15px 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-size: cover;

  color: white;
  box-sizing: border-box;

  > * {
    z-index: $zProfileCardContent;
  }

  &__username {
    margin-bottom: 2px;
    max-height: 35px;

    flex-grow: 1;
    align-self: flex-end;

    overflow: hidden;

    font-weight: bold;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 500;

    opacity: 0.8;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%);

    box-sizing: border-box;
  }
}