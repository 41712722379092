@import '../../sass/colors';
@import '../../sass/utils';
@import '../../sass/z-indexes';

$height: 60px;

.BetsStatusPanel {
  position: sticky;
  bottom: 0;
  height: $height;
  padding: 0 20px;
  margin: 0 -20px;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  background-color: white;
  box-sizing: content-box; // in order to have padding token into account with width 100 %
  box-shadow: rgba(0, 0, 0, 0.32) 0 5px 30px, rgba(0, 0, 0, 0.32) 0 0 15px;

  transition:
          height 375ms $mdEasingStandard,
          background-color 375ms $mdEasingStandard;

  z-index: $zGameBetsTabSavingProgress;

  &--hidden {
    height: 0;
  }

  &--success {
    background-color: lighten($success, 30%);
  }

  &__success-info {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  &__saving-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    background-color: white;
    z-index: $zGameBetsTabSavingProgress;
  }
}