$black: #372D2D;
$white: #FCFCFC;
$grey: #808080;
$lightGrey: #B4B4B4;
$darkGrey: #292929;

$purple: #974578;
$gold: #CD9B1D;
$darkGold: #8B6914;
$lightGold: #E9C467;

$success: #58ce38;
$error: #ce3838;

$betInput: $white;
$betInputEditing: hsl(51, 100%, 47%);
$betResultNiete: hsl(18, 85%, 40%);
$betResultTendenz: hsl(45, 85%, 40%);
$betResultRemisTendenz: hsl(45, 85%, 40%);
$betResultDifferenz: hsl(180, 85%, 40%);
$betResultVolltreffer: hsl(138, 85%, 40%);

// some nice Pantone colours
$pantoneTpxSilver: #999B9B;
$pantoneCherryBlossom: #F4CFE0;
$pantoneAshRose: #B27F7F;
$pantoneTcxHarborMist: #AFB1B4;

$ribbonScore: darken($pantoneAshRose, 20%);
$ribbonNeutral: darken($pantoneTcxHarborMist, 5%);