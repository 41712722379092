@import '../sass/colors';
@import '../sass/globals';
@import '../sass/utils';
@import '../sass/z-indexes';

.BetsPage {
  &__tabs {
    background-color: $white;
    position: sticky;
    top: 0;

    transition: top 200ms $mdEasingDecelerate;
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    z-index: $zBetsTabs;

    &.header-offset {
      top: $headerHeight;
    }
  }
}
