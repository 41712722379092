@mixin skeleton($animation-name, $shine-width: 25%, $shine-duration: 1.1s, $shine-animation: ease-in-out,
                $shine-overflow: 30%, $background-color: #e3e3e3, $gradient-color: #fff) {

  background-color: $background-color;
  background-repeat: repeat-y;
  background-size: $shine-width 500px;
  background-position: 0 0;

  background-image: linear-gradient(to right,
          rgba($gradient-color, 0) 0%,
          rgba($gradient-color, 0.7) 50%,
          rgba($gradient-color, 0) 100%
  );

  animation: $animation-name $shine-duration infinite $shine-animation;
  @keyframes #{$animation-name} {
    from { background-position: -$shine-overflow 0; }
    to { background-position: 100% + $shine-overflow 0; }
  }
}