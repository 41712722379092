.Foyer {
  &__first-visit {
    right: 0;
    bottom: 50px;
    left: 0;
    max-width: 480px;

    margin: 0 auto;

    z-index: 10000;
  }

  &__standings {
    display: flex;
    flex-direction: column;
    max-width: 1480px;
    margin: 0 auto;
    padding-bottom: 20px;

    @media only screen and (min-width : 768px) {
      flex-direction: row;
    }
  }

  &__news {
    margin-top: 50px;
  }
}