@import '../sass/colors.scss';

.GameCardGameInfo {
  font-family: 'Oswald', sans-serif;
  font-size: 22px;

  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 98%;
  padding: 3px 0 0;

  &__kickoff, &__result {
    font-size: 26px;
    font-weight: 600;
    flex-grow: 1;
  }

  &__userBet {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;

    font-size: 14px;
    font-weight: 400;

    color: white;
    opacity: 0.75;
  }

  &__city {
    padding: 0 3px 5px;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
  }

  &__points {
    padding: 10px 0;
    margin: auto;
    border: 1px solid white;
    box-sizing: border-box;
  }

  &__game-running-crown {
    margin: 7px 0;

    img {
      width: 18px;
      height: 18px;

      animation: twist 2.5s infinite linear;
      @keyframes twist {
        0% {
          transform: translateX(0) rotate(0deg);
        }
        25% {
          transform: translateX(20px) rotate(90deg);
        }
        50% {
          transform: translateX(0px) rotate(180deg);
        }
        75% {
          transform: translateX(-20px) rotate(270deg);
        }
        100% {
          transform: translateX(0) rotate(359deg);
        }
      }
    }
  }

  &__game-running-text {
    font-size: 12px;

    animation: glow 2s infinite linear;
    @keyframes glow {
      0% { opacity: 1; }
      50% { opacity: 0.25; }
      100% { opacity: 1; }
    }
  }

  &__city, &__userBet, &__points {
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;
  }
}
