.ProfileForm {
  box-sizing: border-box;
  margin: 0 auto;
  text-align: left;

  &__container {
    margin: 0 auto 50px;

    @media only screen and (min-width : 480px) {
      max-width: 500px;
    }
  }

  &__loading-spinner,
  &__loading-error {
    margin: 50px 0;
  }

  &__name-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__button-wrapper {
    text-align: center;
    width: 100%;
  }

  &__save-feedback {
    margin-top: 25px;
  }
}