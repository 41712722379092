body {
  margin: 0;
  padding: 0;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

// Remove automatically appearing clear/reveal icons in IE
// https://stackoverflow.com/a/23067969
input[type="text"]::-ms-clear,
input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

a {
  text-decoration: none;
}
