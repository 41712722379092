@import '../../sass/colors';

.AddPostForm {
  margin: -60px 0 50px;
  text-align: left;
  max-width: 1024px;
  box-sizing: border-box;

  @media only screen and (min-width: 480px) {
    margin: -90px 10px 50px;
  }

  &__paper {
    padding: 15px;
  }

  &__heading {
    margin: 0;
  }

  .ql-editor {
    font-family: 'Roboto', sans-serif;
    font-size: 130%;
  }

  &__draft-info {
    text-align: right;
    font-size: 14px;
    color: $lightGrey;

    &--success,
    &--error {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &--success { color: $success; }
    &--error { color: $error; }
  }

  &__button-row {
    display: flex;
    justify-content: flex-end;
  }

  &__feedback {
    margin-top: 10px;
    text-align: center;
  }
}