.Page {
  min-height: 100vh;
  min-width: 320px;

  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
  }
}