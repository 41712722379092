@import '../sass/colors.scss';

.GameCardScoreEditor {
  padding: 0 3px;
  width: 100%;
  font-family: 'Oswald', sans-serif;
  box-sizing: border-box;

  &__goal-input-row {
    height: 32px;
    padding: 0 2px 3px;
    font-size: 18px;

    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;

    text-align: center;
  }

  &__up-arrow-row,
  &__down-arrow-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__up-arrow-row {
    margin-top: 2px;
  }

  &__arrow:hover {
    cursor: pointer;
  }
}
