.PasswordResetForm {
  position: relative;
  padding: 20px;
  margin: 30px auto;
  width: 100%;
  box-sizing: border-box;

  @media only screen and (min-width : 480px) {
    max-width: 480px;
  }
}