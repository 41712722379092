@import '../sass/colors.scss';

.DonatePage {
  &__content {
    width: 100%;
    padding: 15px;
    margin: 0 auto;

    box-sizing: border-box;

    @media only screen and (min-width : 480px) {
      max-width: 640px;
    }

    a, a:hover, a:focus, a:active {
      color: $purple;
      text-decoration: none;
    }
  }
}