@import '../../sass/colors';
@import '../../sass/utils';

.Post {
  &__card {
    margin: 20px 0;
    overflow: hidden;
    text-align: left;

    @media only screen and (min-width: 480px) {
      margin: 20px 10px;
    }
  }

  &__card-title {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px 6px, rgba(0, 0, 0, 0.07) 0 1px 4px;
    z-index: 0;
  }

  &__card-title:after {
    position: absolute;
    content: '';
    background: url(../../theme/img/damask-pattern-400.jpg) repeat;
    opacity: 0.65;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  &__content {
    position: relative;

    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
      padding: 0;
    }
  }

  &__content--wrapped {
    // keep this in sync with the Post prop "wrapFromHeight"!
    max-height: 300px;
    overflow-y: hidden;
  }

  &__content-show-all {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    line-height: 70px;

    text-align: center;

    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 0.8) 20%,
            rgba(255, 255, 255, 1) 60%,
            rgba(255, 255, 255, 1) 100%
    );
  }

  &__author-and-comments,
  &__add-comment,
  &__comments {
    background-color: lighten($lightGrey, 28%);
  }

  &__author-and-comments {
    padding: 0;
    border-top: 1px solid lighten($lightGrey, 20%);
  }

  &__author-avatar:hover {
    cursor: pointer;
  }

  &__comments-label-desktop {
    display: none;
    @media only screen and (min-width: 480px) {
      display: inline-block;
    }
  }

  &__comments-label-mobile {
    @media only screen and (min-width: 480px) {
      display: none;
    }
  }

  &__comments {
    max-height: 85vh;
    overflow-y: auto;
  }
}