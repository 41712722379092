@import '../sass/colors.scss';
@import '../sass/z-indexes.scss';
@import '../sass/mixins/game-card-ribbon.scss';

.GameCardRibbon {
  &.score { @include game-card-ribbon($ribbonScore, 65px, 75px); }
  &.skeleton { @include game-card-ribbon(lighten($ribbonNeutral, 20%), 65px, 75px); }
  &.neutral { @include game-card-ribbon($ribbonNeutral, 65px, 75px); }
  &.niete { @include game-card-ribbon($betResultNiete, 65px, 75px); }
  &.tendenz { @include game-card-ribbon($betResultTendenz, 65px, 75px); }
  &.remis_tendenz { @include game-card-ribbon($betResultRemisTendenz, 65px, 75px); }
  &.differenz { @include game-card-ribbon($betResultDifferenz, 65px, 75px); }
  &.volltreffer { @include game-card-ribbon($betResultVolltreffer, 65px, 75px); }

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;

  z-index: $zGameCardRibbon;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    user-select: none;

    box-sizing: border-box;
    z-index: $zGameCardRibbonContent;
  }
}
