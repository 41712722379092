@import '../sass/utils';
@import '../sass/z-indexes';

.SchedulePage {
  &__toolbar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;

    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);

    font-family: 'Roboto', sans-serif;
    transition: top 200ms $mdEasingDecelerate;

    z-index: $zScheduleToolbar;
    box-sizing: border-box;

    // Fix for sticky toolbar on IE
    &.js-is-sticky,
    &.js-is-stuck {
      width: 100%;
    }
  }

  &__toolbar-title {
    margin: 0;
    padding: 10px 5px;
    text-align: left;
    width: 100%;

    @media only screen and (min-width : 480px) {
      margin: 0 20px 0 10px;
      padding: 0;
      width: auto;
    }
  }

  &__game-container {
    margin: 10px 20px;
  }

  &__empty-state {
    padding: 100px 20px;
    font-size: 22px;
  }

  &__add-game-button {
    position: sticky;
    margin-right: 4px;
    bottom: 95px;
    text-align: right;
    z-index: $zAddGameButton;
  }
}

.GameCard__click-wrapper {
  &:hover {
    cursor: pointer;
  }
}