@import '../../sass/colors';

.DrawerMenu {
  text-align: left;

  &__payment-hint {
    padding: 15px;
    text-align: center;

    > * {
      margin: 5px 0;
    }
  }

  &__paypal-link {
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &:hover {
      background-color: rgba(55, 45, 45, 0.1);
    }
  }

  &__bank-account {
    font-size: 14px;
  }

  a {
    color: $black;
    text-decoration: none;

    &:hover, &:focus, &:active {
      color: $black;
      text-decoration: none;
    }
  }
}