.AboutPage {
  &__content {
    font-family: 'Roboto', sans-serif;
    margin: 15px 10px 50px;

    @media only screen and (min-width : 480px) {
      width: 480px;
      margin: 25px auto 50px;
    }
  }
}