.ReceptionPage {
  &__content {
    position: relative;
    margin-top: -40px;
  }

  &__about {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    margin: 50px 10px;
    padding: 0 20px;

    @media only screen and (min-width : 480px) {
      max-width: 640px;
      margin: 50px auto;
    }
  }
}