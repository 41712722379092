@import 'sass/z-indexes.scss';

body {
  font-family: 'Roboto', sans-serif;
  font-display: swap;
  text-align: center;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    font-style: normal;
  }

  h1, h2, h3 {
    text-transform: uppercase;
  }

  h4 {
    text-decoration: underline;
  }

  h1 { font-size: 30px; }
  h2 { font-size: 28px; }
  h3 { font-size: 24px; }
  h4 { font-size: 24px; }
  h5 { font-size: 22px; }
  h6 { font-size: 20px; }

}

.App__demo-indicator {
  position: fixed;

  top: 0;
  width: 90px;
  margin: 0 calc(50% - 45px);
  padding: 10px;

  font-size: 10px;

  pointer-events: none;

  background-color: rgba(255, 255, 0, 0.6);
  z-index: $zDemoIndicator;
  box-sizing: border-box;
}
