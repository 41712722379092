@import '../../sass/colors';
@import '../../sass/utils';

// TODO P3 make expanding of bet stats smoother
.BetStatsPanel {
  margin: 0 auto;
  width: 95%;

  &.open &__inner {
    width: 100%;
    padding-top: 10px;
    border: 1px solid $lightGrey;
    border-radius: 5px;
  }

  &__chart-wrapper {
    position: relative;
  }

  @media (min-width: 480px) {
    margin: 0 auto;
  }
}