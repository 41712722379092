@mixin game-card-ribbon($color, $wrapped-el-height, $width) {
  $added-height: 15px;
  $bottom-arrow-height: 10px;
  $ribbon-callout-width: $added-height - 0.2*$added-height;
  $darken-left-right: 4%;
  $darken-ribbon-callout: 20%;

  box-sizing: border-box;

  position: absolute;
  top: -$added-height;
  left: calc(50% - #{$width / 2});
  height: $wrapped-el-height + 2 * $added-height;

  background: $color;
  box-shadow: -6px 3px 19px -7px rgba(0, 0, 0, 0.75);
  width: $width;
  min-width: $width;

  &:before {
    position: absolute;
    top: 0;
    left: -$ribbon-callout-width;
    content: "";
    width: 0;
    height: 0;

    border-bottom: $added-height solid darken($color, $darken-ribbon-callout);
    border-left: $ribbon-callout-width solid transparent;
  }

  &:after {
    border-top: $bottom-arrow-height solid $color;
    border-left: $width / 2 solid transparent;
  }

  // TODO P3 how to remove hard dependency to parent class name for the __right subelement?
  &:after, > .GameCardRibbon__right:after {
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    bottom: -$bottom-arrow-height;
    width: 0;
  }

  > .GameCardRibbon__right {
    width: $width / 2;
    height: 100%;

    position: absolute;
    right: 0;
    top: 0;

    background: darken($color, $darken-left-right);

    &:after {
      left: -0.5px;
      border-right: $width / 2 solid transparent;
      border-top: $bottom-arrow-height solid darken($color, $darken-left-right);
    }
  }
}