.ChangePasswordForm {
  margin: 0 auto 25px;
  max-width: 500px;

  text-align: left;
  
  &__title {
    text-align: center;
  }

  &__button-wrapper {
    text-align: center;
    width: 100%;
  }

  &__save-feedback {
    margin-top: 25px;
  }
}