@import '../../sass/colors';

.CurrentGameCard {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  @media only screen and (min-width : 375px) {
    padding: 0 40px;
  }
}