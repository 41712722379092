.StandingsOverview {
  min-width: 320px;

  @media only screen and (min-width: 768px) {
    padding: 0 15px;
  }

  @media only screen and (min-width: 1480px) {
    min-width: 480px;
  }
}