@import '../sass/colors';

// keep in sync with avatar size in React inline-style!
$avatarSize: 140px;

.UserDetailsPopover {
  width: 300px;
  min-width: 300px;
  max-width: 300px;

  margin: 0;

  background: transparent;

  &__content {
    position: relative;

    padding: 20px 10px 10px;
    margin-top: -50px;
    text-align: center;

    &:before {
      position: absolute;
      content: '';
      background-color: white;
      border-radius: 10px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -2;
    }

    &:after {
      position: absolute;
      content: '';
      background: url(../theme/img/damask-pattern-400.jpg) repeat;
      opacity: 0.85;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
    }
  }

  &__avatar-wrapper {
    position: absolute;
    top: -0.7 * $avatarSize;
    left: 0;

    width: 100%;
    text-align: center;
  }

  &__avatar-background {
    width: $avatarSize;
    height: $avatarSize;

    margin: auto;

      border: 5px solid white;
      border-radius: 100%;
      background-color: white;

      box-sizing: border-box;
  }

  &__close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__username {
    color: $purple;
  }

  &__about {
    margin: 0 0 15px;
    font-size: 24px;
    font-style: italic;

    color: $darkGrey;
    font-weight: 300;
  }

  &__location {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $grey;
    font-size: 14px;
    font-weight: 500;
  }

  &__username {
    margin-bottom: 15px;
  }

  animation: appear 375ms cubic-bezier(0.23, 1, 0.32, 1);
  @keyframes appear {
    from { opacity: 0; }
    to { opacity: 1; }
  }
}