@import '../sass/mixins/skeleton';
@import '../sass/z-indexes';

.BigPicture {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 20px;

  height: 125px;
  @media only screen and (min-width : 480px) {
    height: 150px;
  }
  @media only screen and (min-width : 1024px) {
    height: 250px;
  }

  &--large {
    height: 200px;
    @media only screen and (min-width : 480px) {
      height: 250px;
    }
    @media only screen and (min-width : 1024px) {
      height: 350px;
    }
  }

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-size: cover;
  background-position-x: 50%;

  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;

  box-sizing: border-box;

  &--skeleton {
    @include skeleton('big-picture-placeholder', $shine-width: 40%, $shine-overflow: 70%);
  }

  &__heading {
    font-weight: 700;
    font-size: 28px;

    @media only screen and (min-width : 768px) {
      font-size: 36px;
    }

    color: white;
    z-index: $zBigPictureHeading;

    text-shadow: black 0 0 6px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';

    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
  }
}