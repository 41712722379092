@import '../../sass/_z-indexes.scss';

.News {
  position: relative;
  margin: 20px auto;
  max-width: 1024px;

  &__empty-state {
    margin: 75px 0;
    font-size: 24px;
    opacity: 0.7;
  }
}