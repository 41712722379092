$zCurrentGamesScrollButtons: 1;
$zBigPictureHeading: 50;
$zGameCardRibbon: 90;
$zGameCardRibbonContent: 100;
$zProfileCardContent: 100;
$zGameCardFlags: 200;
$zGameBetsTabSavingProgress: 400;
$zGameBetsTabSaveButton: 500;
$zAddGameButton: 700;
$zBetsTabs: 1000;
$zScheduleToolbar: 1000;
$zHeader: 1100;
$zDemoIndicator: 10000;